.contact-section {
  margin-top: 2.6rem;
}

.contact-bg {
  height: 25vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),
    url(./buildings.jpg);
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-bg h2 {
  font-size: 2rem;
  text-transform: uppercase;
  padding: 0.4rem 0;
  letter-spacing: 4px;
}

.line div {
  margin: 0 0.2rem;
}

.line div:nth-child(1),
.line div:nth-child(3) {
  height: 3px;
  width: 70px;
  background-color: var(--allbgcolor);
  border-radius: 5px;
}

.line {
  display: flex;
  align-items: center;
}

.line div:nth-child(2) {
  width: 10px;
  height: 10px;
  background: #687eff;
  border-radius: 50%;
}

.c-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #093b54;
}

.c-text {
  font-weight: 300;
  opacity: 0.9;
  width: 75%;
  align-items: center;
  text-align: center;
}

.contact-bg .c-text {
  margin: 1.6rem 0;
}

.contact-body {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 1rem;
}

.contact-info {
  margin: 0.5rem 0;
  text-align: center;
  padding: 1rem 0;
}

.contact-info span {
  display: block;
}

.contact-info div {
  margin: 0.3rem 0;
  padding: 0.5rem;
}

.contact-info .fas {
  font-size: 2rem;
  padding-bottom: 0.9rem;
  color: #093b54;
}

.contact-info div span:nth-child(2) {
  font-weight: 500;
  font-size: 1.1rem;
}

.contact-info .c-text {
  padding-top: 0.4rem;
  line-height: 1.5;
}

.contact-form {
  padding: 2rem 0;
  border-top: 1px solid #c7c7c7;
}

.contact-form form {
  padding-bottom: 1rem;
}

.form-control {
  width: 100%;
  border: 1.5px solid #c7c7c7;
  border-radius: 5px;
  padding: 0.7rem;
  margin: 0.6rem 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  outline: 0;
}

.form-control:focus {
  box-shadow: 0 0 6px -3px rgba(48, 48, 48, 1);
}

.contact-form form div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.6rem;
}

.send-btn {
  background-color: var(--allbgcolor);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial,
    sans-serif;
  height: 40px;
  line-height: 40px;
  outline: 0;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 30%;
  border: 0;
  transition: box-shadow 0.2s;
}

.send-btn:hover {
  background-color: #80b3ff;
  box-shadow: #b6fffa;
}

.contact-form > div img {
  width: 85%;
}

.contact-form > div {
  margin: 0 auto;
  text-align: center;
}

.contact-footer {
  padding: 2rem 0;
  background: #000;
}

.contact-footer h3 {
  font-size: 1.3rem;
  color: #fff;
  margin-bottom: 1rem;
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: center;
}

.social-links a {
  text-decoration: none;
  width: 40px;
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.4rem;
  transition: all 0.4s ease;
}

.social-links a:hover {
  color: #f7327a;
}

@media screen and (min-width: 768px) {
  .contact-bg .ctext {
    font-size: 1.5rem;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .contact-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .contact-bg .c-text {
    width: 50%;
  }
  .contact-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
}

@media screen and (min-width: 1200px) {
  .contact-info {
    grid-template-columns: repeat(3, 1fr);
  }
}

.cc-form {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 5rem;
}

.c-form-content h1 {
  font-weight: bold;
  margin: 0;
  color: #093b54;
}

.form-container h2 {
  text-align: center;
}

.c-overlay .c-overlay-panel p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.c-form-content span {
  font-size: 12px;
}

.form-container a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.c-button {
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid #b6fffa;
  background-color: var(--allbgcolor);
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.c-button:active {
  transform: scale(0.95);
}

.c-button:focus {
  outline: none;
}

.cc-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 999px;
  border: 1px solid #b6fffa;
  background-color: var(--allbgcolor);
  color: #ffffff;
  font-size: 35px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.cc-button:active {
  transform: scale(0.95);
}

.cc-button:focus {
  outline: none;
}

button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

.c-form-content {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.c-form-content > input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

textarea {
  resize: none;
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.c-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 500px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.c-container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.c-container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.c-overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.c-container.right-panel-active .c-overlay-container {
  transform: translateX(-100%);
}

.c-overlay {
  background: var(--allbgcolor);
  background: -webkit-linear-gradient(to right, #1f428f, #1f428f);
  /* background: linear-gradient(to right, #1F428F, #1F428F); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.c-container.right-panel-active .c-overlay {
  transform: translateX(50%);
}

.c-overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.c-overlay-panel p {
  padding: 20px;
}

.c-overlay-left {
  transform: translateX(-20%);
}

.c-container.right-panel-active .c-overlay-left {
  transform: translateX(0);
}

.c-overlay-right {
  right: 0;
  transform: translateX(0);
}

.c-container.right-panel-active .c-overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
 
}

@media screen and (max-width: 968px) {
  .c-container {
    border-radius: 7px;
    min-height: 575px;
  }
  .c-container h1 {
    font-size: 20px;
  }
  .c-form-content {
    padding: 25px;
  }
}
