.h-wrapper{
    /* background: url(./picbg1.jpg); */
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
                url(./picbg1.jpg);
    height: 120vh;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}

.h-wrapper .container{
    padding-top: 7rem;
    margin-top: 1.3rem;
}

.h-container{
    display: flex;
    height: 80vh;
    align-items: center;
    justify-content: space-around;
    position: relative;
}



/* .h-left > :nth-child(2){
    transform :translateX(-6.7rem);
}

.h-left > :nth-child(3){
    transform :translateX(3.7rem);
} */


.h-right{
    margin-left: 25rem;
    flex:1.25;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.h-title{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: #E9EFEF;
    font-size: 3.2rem;
    font-weight: bold;
    text-transform: capitalize;}

.h-title > :nth-child(3){
    color:#E9EFEF;
    font-size: 1.4rem;
    text-transform: capitalize;
} 
.h-title > :nth-child(2){
    color:#E9EFEF;
    text-transform: capitalize;
    font-size: 2rem;
} 
.h-dis{
    color: #E9EFEF;
    font-size: 1.2rem;
    line-height: 2rem;
}

@media(max-width:1024px){
    .h-wrapper{
        height: max-content;
    }
    .h-wrapper .container{
        padding-top: 10rem;

    }
    .h-container{
        height: auto;
        flex-direction: column;
        align-items: center;
        gap: 4rem;
        padding-bottom: 8rem;
    }

    .h-right{
        flex: 1;
        align-items: center;
        margin: 0%;

    }
    .h-title{
        align-items: center;
        font-size: 2.6;

    }
    .h-dis{
        width: 80%;
        text-align: center;
    }
    .h-title > :nth-child(3){
        
        font-size: 1.1rem;
        
    } 
    .h-title > :nth-child(1){
        
        font-size: 2.8rem;
        
    } 
    .h-title > :nth-child(2){
        color:#E9EFEF;
        text-transform: capitalize;
    
        font-size: 1.5rem;
    } 
    .h-dis{
        font-size: 1rem;

    }

}