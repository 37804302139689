.hiw-wrapper {
  padding-top: 2.6rem;
  margin-bottom: 2rem;
}
.hiw-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.hiw-head {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  max-width: 38rem;
  margin: auto;
}
.hiw-head .title{
  color: #093B54;
}
.hiw-features {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}
.hiw-feature {
  max-width: 38rem;
  display: flex;
  align-items: center;
  background-color: #093B54;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  width: max-content;
}
.hiw-feature .detail {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  padding-left: 0.5rem;
  padding-bottom: 1rem;
  /* translate: -18% 0rem; */
}

.hiw-feature .detail > :nth-child(1) {
  font-weight: bold;
  font-size: 1.1rem;
}

.hiw-feature:nth-of-type(2) {
  margin-left: 10rem;
}
.hiw-feature:nth-of-type(1),
.hiw-feature:nth-of-type(3) {
  margin-right: 5rem;
}

@media (max-width: 1280px) {
  .hiw-feature {
    max-width: 80%;
  }
  .hiw-feature:nth-of-type(2) {
    margin-left: 0rem;
  }
  .hiw-feature:nth-of-type(1),
  .hiw-feature:nth-of-type(3) {
    margin-right: 0rem;
  }
  .hiw-feature .detail{
    translate: 0;
  }
  .hiw-feature .hiw-icon img{
    translate: 0;
  }
}
@media (max-width: 576px){
    .hiw-feature{
        flex-direction: column-reverse;
    }
}

.hiw-feature:hover {
  transform: translateY(-8px);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
