@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .m-wrapper{
      position: relative;
  }
  
  .m-container{
      display: flex;
      justify-content: center;
      padding: 2.6rem;
      gap: 2rem;
  }
  
  .m-left{
      background: #093B54;
      flex: 30%;
      display: flex;
      padding: 0 1.8rem;
      padding-top: 3rem ;
      flex-direction: column;
      gap: 1.5rem;
      border-radius: 25px;
      opacity: 0; /* Initially hide */
  }
  .m-left .tag{
      color: whitesmoke;
      text-align: center;
  }
  .m-left .text{
      text-align: center;
      font-size: .8rem;
      line-height: 2rem;
      color: whitesmoke;
  }
  .lottie{
      height: 14rem;
  }
  .m-right{
      flex: 70%;
      opacity: 0; /* Initially hide */
  }
  .map{
      border-radius: 25px;
      border-color: black ;
      width: 100%;
      height: 580px;
  }
  
  .fade-in {
      animation: fadeIn 1s ease-out;
      opacity: 1; /* Show when animation is applied */
  }
  
  @media (max-width:1080px){
      .m-container{
          flex-direction: column;
      }
  }
  