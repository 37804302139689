body {
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
    margin: 0;
    padding: 0;
}

.App {
    text-align: center;
}

.container {
    width: 80%;
    margin: 0 auto;
}

main {
    padding: 20px 0;
}

main h2 {
    font-size: 32px;
    color: #093B54;
    margin-bottom: 10px;
}

main p {
    font-size: 18px;
    color: #666;
    margin-bottom: 20px;
}
.button-container {
    display: flex;
    gap: 10px; 
    justify-content: center;
    flex-wrap: wrap;
}
.button-container button{
    background-color: #fff;
    cursor: pointer;
}
.career-bg {
    height: 40vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),
        url(./contact-bg.jpg);
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.career-bg h1 {
    font-size: 3rem;
    text-transform: uppercase;
    padding: 0.4rem 0;
    letter-spacing: 4px;
}

.career-section {
    margin-top: 2.6rem;
}

.career-content h2 {
    font-size: 28px;
    color: #093B54; /* Changed color to blue */
    margin-bottom: 20px;
}

.career-content p {
    font-size: 16px;
    color: #093B54; /* Changed color to blue */
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .career-bg .ctext {
        font-size: 1.5rem;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 992px) {
    .career-bg .c-text {
        width: 50%;
    }
}

@media screen and (min-width: 1200px) {
    .container {
        width: 80%;
    }
}
.career-section {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .career-section.visible {
    opacity: 1;
  }
  
  /* Add more styles as necessary */
  .cardsB {
    width: 180px; 
    height: 220px; 
    padding: 25px;
    border: 1px solid #ddd;
    border-radius: 15px; 
    background-color: #f0f4f8; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
    transition: all 0.1s linear, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    overflow: hidden;
}

.cardsB img {
    width: 100%;
    height: 120px;
    object-fit: contain;
    border-radius: 15px 15px 0 0; 
    transition: transform 0.3s ease; 
}

.cardsB h3 {
    margin: 10px 0 5px;
    font-size: 18px;
    letter-spacing: 1px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #34495e; 
}

.cardsB p {
    font-size: 14px;
    color: #95a5a6;
    /* font-style: italic; */
    font-family:  Arial, Helvetica, sans-serif;
    margin: 0 10px;
}

.cardsB:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.cardsB:hover img {
    transform: scale(1.1); /* Slight zoom effect on image */
}
