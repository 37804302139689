.n-wrapper{
    background: #0003;
    width: 100%;
    position: absolute;
    z-index: 999;/*will make our navbar to the top of every element*/
    transition:  0.6s ;
}
.sticky{
    position: fixed;
    box-shadow: 10px 12px 48px rgba(181, 187, 203, .206);
    background-color: white;
    color: black;
    top: 0;
}

.n-container{
    padding: 0rem 1rem;
    color: white;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    transition:  0.6s ; 
}
.sticky .n-container{
    color: black;
    padding: 0rem 1rem;
    transition:  0.6s ; 
}

.n-logo{
    cursor: pointer;
}
.logo-image{
    /* padding: .4rem .4rem; */
    margin: 5px;
    height: 65px;
    width: 100%;
}
.n-right{
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    font-size: 0.9rem;
}
.options{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
}

.options span{
    cursor: pointer;
}

.get-quote{
    color: whitesmoke;
    background-color: #84C602;
    padding: 1rem 1.6rem;
    font-size: 1rem;
    ;
    text-transform: capitalize;
    border-radius: 999px;
    box-shadow: 0px 0.1px 8px 0.1px rgb(168, 163, 163);
    cursor: pointer;
    transition: all 0.5s ease;
}

.get-quote:hover{
    background-color: #98e204;
}

.nm-container{
    display: none;
}

@media(max-width:1200px){
    .n-container{
        display: none;
    }
    .nm-container{
        display: block;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1rem;
        position: relative;
        background-color: var(--allbgcolor);
    }
    .nm-container >:nth-child(1){
        font-weight: bold;
    }
    .m-options{
        position: fixed;
        top: 4rem;
        padding: 3rem 2rem;
        box-sizing: border-box;
        display: flex;
        left: 0;
        background-color: var(--allbgcolor);
        flex-direction: column;
        width: 100%;
        font-size: 1.2rem;
        z-index: 99;/*top of each element*/
        height: calc(100vh - 4rem);
        transform: translateX(-150%);
        transition: transform 0.3s ease-in-out;
    }
    .m-options a{
         margin-bottom: 2rem; 
         padding-bottom: 1rem;
         border-bottom: 1px solid var(--border-color);
    }
    .mget-quote{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 1rem 0rem;
        color: white;
        background-color: #84C602;
        padding: 1rem 1.6rem;
        border-radius: 999px;
        box-shadow: 0px 1px 8px 1px white;
        cursor: pointer;
        transition: all 0.5s ease;
    }
    .n-logo{
        cursor: pointer;
   }
   .logo-image{
       /* padding: .4rem .4rem; */
       /* margin: 2px; */
       height: 45px;
       width: 100%;
   }
}
.boptioncolor{
    color: rgb(0, 0, 0);
    text-decoration: none;
}
a{
    text-decoration: none;
}
.optioncolor{
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.sticky a {
    color: black;
}
