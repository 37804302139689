.emailbox {
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 999px;
    display: flex;
    align-items: center;
    width: 70%; /* 70% of actual div */
    position: relative;
    overflow: hidden;
    font-size: larger;
  }
  
  .emailbox > input {
    outline: none;
    border: none;
    margin-left: 1rem;
    font-size: 0.6rem;
    color: rgb(150, 150, 150);
    font-family: '__Josefin_Sans_584c1b', '__Josefin_Sans_Fallback_584c1b';
    flex: 1;
    font-size: large;
  }
  
  .emailbox > input::placeholder {
    font-size: 1rem;
  }
  
  .mail-quote {
    position: absolute;
    right: 0.5rem;
    height: 80%;
    background-color: #84c602;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0rem 2rem;
    font-size: 14px;
    border-radius: 999px;
    color: #e9efef;
  }
  
  .mail-quote:hover {
    background-color: #8fd700;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .emailbox > input {
      display: none; /* Hide the email input */
    }
  
    .mail-quote {
      display: none; /* Hide the button */
    }
  
    .emailbox {
      background-color: transparent; /* Remove the white background */
      padding: 0; /* Remove padding */
      width: auto; /* Adjust width */
    }
  
    /* Hide the email logo */
    .emailbox > div:first-child {
      display: none;
    }
  }