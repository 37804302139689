.section{
    width: 85%;
    margin-top: 160px;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
}

@media (max-width:900px){
    .section{
        width: 90%;
    }
}

.inner-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 50px;
    background: #093B54;
    border-radius: 20px;
}

.inner-section .section-video .video{
    width: 500px;
    height: 300px;
    border-radius: 12px;
    object-fit: cover;
}

.section-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    height: auto;
}

.section-text h5{
    margin-bottom: 2rem;
    font-size: 1.6rem;
    color: whitesmoke;
}

.section-text .text{
    opacity: 0.8;
    font-size: 16px;
    margin-bottom: 40px;
    color: #E9EFEF;
}

.section-text a{
    padding: 12px 15px;
    white-space: nowrap;
}

.section-text .s-button{
    display: flex;
    right: 0.5rem;
    height: 3rem;
    width: 8rem;
    background-color: #84C602;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem 2rem;
    border-radius: 999px;
    color: white;
    cursor: pointer;
    border: 0;
    outline: 0;
    box-shadow: 0px 1px 8px 1px white;
}

.s-button:hover{
    background-color: #98e204;
    box-shadow: 0px 1px 8px 1px #E9EFEF;
}

@media (max-width:1080px){
    .section-text h3{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .section-text h1{
        font-size: 28px;
        margin-bottom: 20px;
    }
    .section-text p {
        font-size: 15px;
        margin-bottom: 50px;
    }
}

@media (max-width:765px){
    .inner-section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
    .section-video {
        position: relative;
    }
    .inner-section .section-video .video{
        margin-bottom: 0.5rem;
        width: 150px;
        height: 100px;
    }
    .section-text {
        margin-top: 40px;
        align-items: center;
        text-align: center;
    }
    .section-text h3{
        font-size: 18px;
        margin-bottom: 5px;
    }
    .section-text h1{
        font-size: 24px;
        margin-bottom: 10px;
    }
}
