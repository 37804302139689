
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;500;600;700&display=swap');

:root {
  --dark-bg: #000;
  --light-bg: #f6f7fb;
  --primary-color: #533fd7;
  --secondary-color: #093B54;
  --third-color: #54f3a6;
  --fourth-color: #fee590;
  --text-color:  #E9EFEF;
  --text-secondary:  #E9EFEF;
  --text-color-dark: #12183f;
  --text-color-light: #fcf3d4;
  --title-color:  #E9EFEF;
  --border-color: #6a55f8;
  --border-color-dark: #27263a;
  --border-color-light: #6a55f8;
  --sixth-color:#16ddeb;
  --allbgcolor:#093B54;
  --color: #4070f4;
  --shadow: 10px 12px 48px rgba(181, 187, 203, 0.206);
}

html {
  box-sizing: border-box;
  margin: 0 !important;
  max-width: 100%;
  overflow-x: hidden;
  padding: 0 !important;
}

body {
  color: var(--text-color);
  margin: 0 !important;
  max-width: 100%;
  overflow-x: hidden;
  padding: 0 !important;
  touch-action: pan-y, pan-x;
  transition: all 0.4s cubic-bezier(0.57, 0.25, 0.33, 1) 0s;
  font-family: 'Nunito Sans', sans-serif;
}

.container{
  width:100%;
  margin:auto;
  overflow: hidden;
}
@media (min-width: 2100px){
  .container{
    max-width: 1600px;
  }
}
@media (min-width: 576px){
  .container{
    max-width: 540px;
  }
}
@media (min-width: 786px){
  .container{
    max-width: 720px;
  }
}
@media (min-width: 992px){
  .container{
    max-width: 960px;
  }
}
